<template>
  <dash-page  v-if="$store.state.auth.userType === 'superadmin'"
              :title="$t('FaqEditor')"
              :header-route="localeLink('dashboard/faq')">

    <template #header_action>
      <v-btn @click="saveentityData" block dark :color="wsACCENT" class="noCaps"> {{ $t('Save') }}</v-btn>
    </template>
   

    <v-sheet class="wsRoundedHalf pa-6 mt-6">
      <ws-translation-field v-model="entityData.name" :label="$t('Name')" />
      <wsTranslationText v-model="entityData.content"  :label="$t('Content')" />
      <v-switch v-model="entityData.is_active" :label="$t('Active')" />
    </v-sheet>


    
  </dash-page>
</template>

<script>
import {mapActions} from "vuex";
import wsTranslationText from "@/components/UI/wsTranslationText";

export default {
  name: "faqEditor",
  components : {
    wsTranslationText
  },
  data() {
    return {
      entityData : {
        name : {},
        content : {}
      }
    }
  },
  props : ['uuid'],
  methods : {
    ...mapActions('faq', [
          'GET_FAQ_ITEM_ADMIN',
          'EDIT_FAQ_ITEM'
        ]
    ),
    async saveentityData() {
      if (await this.EDIT_FAQ_ITEM(this.entityData) ) {
        this.notify( this.$t('ChangesSaved'))
      }
    }
  },
  async mounted() {
    if ( this.uuid ) {
      this.entityData = await this.GET_FAQ_ITEM_ADMIN(this.uuid)
    }
  }
}
</script>

<style scoped>

</style>